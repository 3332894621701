<template>
  <div>
    <h2 class="mb-2">
      Developer Apps
    </h2>
    <b-button
      variant="primary"
      size="sm"
      class="mb-2"
      @click="manageApp(data.value)"
    >
      Create New App
    </b-button>
    <b-table
      responsive="sm"
      :items="apps"
      :fields="fields"
    >
      <template #cell(brime_id)="data">
        <b-button
          variant="primary"
          size="sm"
          @click="manageApp(data.value)"
        >
          Manage
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import { BTable, BButton } from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BButton,
  },
  data() {
    return {
      apps: [],
      fields: [
        {
          key: 'name', label: 'Name',
        },
        {
          key: 'app_type', label: 'App Type',
        },
        {
          key: 'client_id', label: 'Client ID',
        },
        { key: 'brime_id', label: 'Actions' },
      ],
    }
  },
  async mounted() {
    if (!this.$auth.user) {
      this.$auth.loginWithRedirect()
    }
    const appLookup = await this.getDevApps()
    this.apps = appLookup.apps
  },
  methods: {
    manageApp(app) {
      console.log(`manage app${app}`)
    },
  },
}
</script>
